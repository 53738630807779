<template>
  <footer class="bg-light text-center text-lg-start mt-auto fixed-bottom">
    <div class="text-center p-3">© 2024 WIRA TAXI</div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
/* Tambahkan style khusus untuk footer jika diperlukan */
</style>
