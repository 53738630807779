<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* Tambahkan style global jika diperlukan */
</style>
